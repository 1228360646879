<template lang="pug">
include ../../../configs/template
div(v-if="isVisibilitySearch")
  h4.my-4.text-center {{ isVisibilityBtnSearchAndClear ? $t('filter.title') : $t('btn.createReport') }}
  div.d-flex
    div.row.mx-1.w-100
      +field('filter.institution_name', '"filter.institution_name"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.institution_name.includes($route.name)" clearable)
      +field('filter.institution_id', '"filter.institution_id"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.institution_id.includes($route.name)" clearable)
      +field('filter.manager', '"filter.managerFullnameOrID"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.manager.includes($route.name)" clearable)
      +field('filter.sailor_search', '"filter.sailorFullnameOrID"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.sailor_search.includes($route.name)" clearable)
      +field('filter.manager_search', '"filter.managerSearch"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.manager_search.includes($route.name)" clearable)
      +field('filter.crewing', '"filter.crewingID"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.crewing.includes($route.name)" clearable)
      +field('filter.crewing_company', '"filter.crewing_company"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.crewing_company.includes($route.name)" clearable)
      +select('filter.crewing', 'listCrewingCompanies' ,'filter.crewing', 'nameLang', 'isMultiStatus').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.isListCrewing.includes($route.name)" clearable)
      +field('filter.id', 'labelID').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.id.includes($route.name)" clearable)
      +field('filter.sailor_name', '"filter.sailorFullname"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.sailor_name.includes($route.name)" clearable)
      +field('filter.sailor_id', '"filter.sailor_id"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.sailor_id.includes($route.name)" clearable)
      +field('filter.protokol_number', '"filter.protokol_number"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.protokol_number.includes($route.name)" clearable)
      +field('filter.number', '"filter.number"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.number.includes($route.name)" clearable)
      +field('filter.statement_number', '"filter.statement_number"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.statement_number.includes($route.name)" clearable)

      +field('filter.company_name', '"crewingName"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.company_name.includes($route.name)" clearable)

      +field('filter.serial', '"filter.serial"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.serial.includes($route.name)" clearable)
      +field('filter.registry_number', '"filter.registry_number"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.registry_number.includes($route.name)" clearable)
        // Report Protocol sqc
      +field('filter.statement_year', '"filter.statement_year"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.statement_year.includes($route.name)" clearable)
      +field('filter.protocol_number', '"filter.protocol_number"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.protocol_number.includes($route.name)" clearable)
      +field('filter.protocol_year', '"filter.protocol_year"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.protocol_year.includes($route.name)" clearable)

      div(v-if="viewsQuery.price_f1.includes($route.name)").w-100.px-0
        div.row.w-100.align-center
          div.col-12.col-sm-12.col-md-6
            +field('price_f1', '"filter.price_f1"').align-end(
              @input="(item) => setInputPriceForm('price_f1', item)"
              type="number"
              clearable)
          div.col-12.col-sm-12.col-md-6
            +radio-group('filedParam_price_f1', 'listFormParams', '')(
              @change="setInputPriceForm('price_f1', price_f1)"
              row)
      div(v-if="viewsQuery.price_f2.includes($route.name)").w-100.px-0
        div.row.w-100.align-center
          div.col-12.col-sm-12.col-md-6
            +field('price_f2', '"filter.price_f2"').align-end(
              @input="(item) => setInputPriceForm('price_f2', item)"
              type="number"
              clearable)
          div.col-12.col-sm-12.col-md-6
            +radio-group('filedParam_price_f2', 'listFormParams', '')(
              @change="setInputPriceForm('price_f2', price_f2)"
              row)

      +select('filter.decision', 'decisions', 'filter.decision', 'nameLang').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.decision.includes($route.name)" clearable)
      +select('filter.direction', 'directions', 'filter.direction', 'valueLang').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.direction.includes($route.name)" clearable)
      +select('filter.branch_office', 'branch_offices', 'filter.branchOffice', 'nameLang').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.branch_office.includes($route.name)" clearable)
      +select('filter.type_user', 'userTypes', 'typeUser', 'nameLang').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.type_user.includes($route.name)" clearable)
      +select('filter.commissioner_type', 'commissioner_types', 'filter.commissioner_type', 'nameLang').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.commissioner_type.includes($route.name)" item-value="abbreviation" clearable)
      +select('filter.status_document', 'statusesList', 'filter.status_document', 'nameLang', 'isMultiStatus').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.status_document.includes($route.name)" clearable)
      +select('filter.nostrification_document_status', 'nostrificationStatusesList', 'filter.nostrification_document_status', 'nameLang', 'isMultiStatus').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.nostrification_document_status.includes($route.name)" clearable)
      +select('filter.issued_by', 'issuedByList', 'filter.issuedBy', 'nameLang')(
        v-if="viewsQuery.issued_by.includes($route.name)" item-value="value" clearable).col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.sailor_birth.includes($route.name)")
        +date-picker('filter.sailor_birth', '"filter.birthDay"')

      +select('filter.faculty', 'listFaculties', 'filter.way', 'nameLang', 'true').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.faculty.includes($route.name)" clearable)
      +select('filter.has_statement', 'mappingApplicationAvailability', 'filter.statementAvailability', '"name"').col-xs-12.col-sm-12.col-md-6.col-lg-4.align-end(
        v-if="viewsQuery.has_statement.includes($route.name)" clearable item-value="value")
      +select('filter.passed_educ_exam', 'mappingResultsEQC', 'filter.resultEKK', '"name"').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.passed_educ_exam.includes($route.name)" clearable item-value="value")
      +select('filter.educ_with_dkk', 'mappingEducExamPass', 'filter.decisionEKK', '"name"').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.educ_with_dkk.includes($route.name)" clearable item-value="value")
      +select('filter.education_institution', 'listInstitution', 'filter.nameInstitution', 'nameLang', 'true').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.education_institution.includes($route.name)" clearable)

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3(v-if="viewsQuery.date_meeting.includes($route.name)")
        +date-picker('filter.date_meeting', '"filter.date_meeting"')
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.from_date_meeting.includes($route.name)")
        +date-picker('filter.from_date_meeting', '"filter.from_date_meeting"')(:max="filter.to_date_meeting")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.to_date_meeting.includes($route.name)")
        +date-picker('filter.to_date_meeting', '"filter.to_date_meeting"')(:min="filter.from_date_meeting")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.from_date_end_meeting.includes($route.name)")
        +date-picker('filter.from_date_end_meeting', '"filter.from_date_end_meeting"')(:max="filter.to_date_end_meeting")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.to_date_end_meeting.includes($route.name)")
        +date-picker('filter.to_date_end_meeting', '"filter.to_date_end_meeting"')(:min="filter.from_date_end_meeting")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.date_start.includes($route.name)")
        +date-picker('filter.date_start', '"filter.date_issued"')
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.date_start__gte.includes($route.name)")
        +date-picker('filter.date_start__gte', '"filter.date_start__gte"')(:max="filter.date_start__lte")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.date_start__lte.includes($route.name)")
        +date-picker('filter.date_start__lte', '"filter.date_start__lte"')(:min="filter.date_start__gte")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.date_end.includes($route.name)")
        +date-picker('filter.date_end', '"filter.date_end"')
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.date_not_bought.includes($route.name)")
        +date-picker('filter.date_not_bought', '"filter.date_not_bought"')

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.date_create.includes($route.name)")
        +date-picker('filter.date_create', 'labelDateCreate')
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.from_date.includes($route.name)")
        +date-picker('filter.from_date', 'labelDateCreateFrom')(:max="filter.to_date" :disabled="Boolean(filter.date_create)")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.to_date.includes($route.name)")
        +date-picker('filter.to_date', 'labelDateCreateTo')(:min="filter.from_date" :disabled="Boolean(filter.date_create)")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.pay_date.includes($route.name)")
        +date-picker('filter.pay_date', 'labelPayDate')
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.from_date_create.includes($route.name)")
        +date-picker('filter.from_date_create', 'labelDateCreateFrom')(:max="filter.to_date_create" :disabled="Boolean(filter.pay_date)")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.to_date_create.includes($route.name)")
        +date-picker('filter.to_date_create', 'labelDateCreateTo')(:min="filter.from_date_create" :disabled="Boolean(filter.pay_date)")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.from_pay_date.includes($route.name)")
        +date-picker('filter.from_pay_date', 'labelFromPayDate')(:max="filter.to_pay_date" :disabled="Boolean(filter.date_create)")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.to_pay_date.includes($route.name)")
        +date-picker('filter.to_pay_date', 'labelToPayDate')(:min="filter.from_pay_date" :disabled="Boolean(filter.date_create)")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.date_processing.includes($route.name)")
        +date-picker('filter.date_processing', 'labelDateProcessing')
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.from_date_processing.includes($route.name)")
        +date-picker('filter.from_date_processing', 'labelDateProcessingFrom')(:max="filter.to_date_processing" :disabled="Boolean(filter.date_processing)")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.to_date_processing.includes($route.name)")
        +date-picker('filter.to_date_processing', 'labelDateProcessingTo')(:min="filter.from_date_processing" :disabled="Boolean(filter.date_processing)")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.event_date.includes($route.name)")
        +date-picker('filter.event_date', '"filter.event_date"')
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.event_date.includes($route.name)")
        +date-picker('filter.from_event_date', '"filter.from_event_date"')(:max="filter.to_event_date" :disabled="Boolean(filter.event_date)")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.to_event_date.includes($route.name)")
        +date-picker('filter.to_event_date', '"filter.to_event_date"')(:min="filter.from_event_date" :disabled="Boolean(filter.event_date)")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.from_set_verification_at.includes($route.name)")
        +date-picker('filter.from_set_verification_at', '"filter.from_set_verification_at"')(:max="filter.to_set_verification_at")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.to_set_verification_at.includes($route.name)")
        +date-picker('filter.to_set_verification_at', '"filter.to_set_verification_at"')(:min="filter.from_set_verification_at")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.date_transfer.includes($route.name)")
        +date-picker('filter.date_transfer', '"filter.date_transfer"')
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.from_date_transfer.includes($route.name)")
        +date-picker('filter.from_date_transfer', '"filter.from_date_transfer"')(:max="filter.to_date_transfer")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.to_date_transfer.includes($route.name)")
        +date-picker('filter.to_date_transfer', '"filter.to_date_transfer"')(:min="filter.from_date_transfer")

      +select('filter.country', 'listCountries', 'filter.country', '"value"', 'true').col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3.align-end(
        v-if="viewsQuery.country.includes($route.name)" clearable)
      +select('filter.rank', 'listRanks', 'filter.rank', 'nameLang', 'true').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.rank.includes($route.name)" clearable)
      +select('filter.position', 'getPositionsList(filter.rank)', 'filter.position', 'nameLang', 'true').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.position.includes($route.name)" clearable :no-data-text="filter.rank ? $t('noData') : $t('selectRank')")
      +select('filter.port', 'listPorts', 'filter.port', 'nameLang', 'true').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.port.includes($route.name)" clearable)
      +select('filter.type_document', 'listTypeDocument', 'filter.type_document', 'nameLang', 'true').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.type_document.includes($route.name)" clearable)
      +select('filter.is_created_by', 'listCreatedBy', 'filter.is_created_by', 'nameLang').col-xs-12.col-sm-12.col-md-12.col-lg-4.col-xl-3.align-end(
        v-if="viewsQuery.is_created_by.includes($route.name)" clearable multiple)
      +select('filter.unsigned_reason', 'unsignedReasons', 'unsignedReason', 'nameLang', 'true').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.unsigned_reason.includes($route.name)" clearable)
      +select('filter.content_type', 'signFailedContentTypesList', 'typeDoc', 'nameLang', 'true').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.content_type.includes($route.name)" item-value="content_type" clearable)
      +select('filter.author', 'userList', 'filter.author', '"userFullName"', 'true').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.author.includes($route.name)" item-value="id" clearable @update:search-input="getList")
      +select('filter.type_exam', 'listTypeExam', 'filter.typeExam', 'nameLang').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.type_exam.includes($route.name)")
      +select('filter.item_status', 'listStatusesDocument', 'filter.status_document', 'nameLang').col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6.align-end(
        v-if="viewsQuery.item_status.includes($route.name)" item-value="value")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.created_at.includes($route.name)")
        +date-picker('filter.created_at', '"filter.created_at"')
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsParams.typeDocument.includes($route.name)")
        +select('params.typeDocument', 'listDistribution', 'type', 'nameLang')(return-object)
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsParams.dictionaryType.includes($route.name)")
        +select('params.dictionaryType', 'listDictionaryType', 'type', 'nameLang')(item-value="value")
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.payment_datetime.includes($route.name)")
        +date-picker('filter.payment_datetime', '"filter.payment_datetime"')

      //- div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.payment_from_datetime.includes($route.name)")
      //-   +date-picker('filter.payment_from_datetime', '"filter.payment_from_datetime"')(:max="filter.payment_to_datetime" :disabled="Boolean(filter.date_create)")
      //- div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.payment_to_datetime.includes($route.name)")
      //-   +date-picker('filter.payment_to_datetime', '"filter.payment_to_datetime"')(:min="filter.payment_from_datetime" :disabled="Boolean(filter.date_create)")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.crewing_group.includes($route.name)")
        +select('filter.crewing_group', 'listGroup', 'filter.group', '"name"')(item-value="id" multiple)
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.course.includes($route.name)")
        +select('filter.course', 'listCourses', 'filter.course', 'nameLang')(item-value="id" multiple)
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.is_payed.includes($route.name)")
        +select('filter.is_payed', 'listPaymentStatus', 'filter.is_payed', 'nameLang')(item-value="status" clearable)
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.level_qualification.includes($route.name)")
        +select('filter.level_qualification', 'listQualificationLevel', 'filter.level_qualification', 'nameLang')(item-value="id" multiple)
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.extent.includes($route.name)")
        +select('filter.extent', 'listExtents', 'filter.extent', 'nameLang')(item-value="id" multiple)
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.qualification.includes($route.name)")
        +select('filter.qualification', 'dinamicListQualificationLevel', 'filter.qualification', 'nameLang')(item-value="id" multiple)
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.speciality.includes($route.name)")
        +select('filter.speciality', 'listProfessions', 'filter.speciality', 'nameLang')(
          item-value="id"
          multiple
          @change="dinamicListSpecializations"
          )
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.specialization.includes($route.name)")
        +select('filter.specialization', 'listSpecializationsForSpeciality', 'filter.specialization', 'nameLang')(item-value="id" multiple)
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.type_delivery.includes($route.name)")
        +select('filter.type_delivery', 'listTypeDelivery', 'delivery', 'nameLang')(item-value="value")

      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.user.includes($route.name)")
        +select('filter.user', 'listUser', 'user', 'nameLang')(item-value="id" multiple)
      div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="viewsQuery.sailor_key.includes($route.name)")
        +select('filter.sailor_key', 'listSailor', 'sailor', '"sailor_full_name"')(item-value="id" @update:search-input="searchSailors")

      // Report SQC
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.direction_abbr.includes($route.name)")
        +select('filter.direction_abbr', 'listWay', 'filter.way', 'nameLang')(item-value="id")
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.committe_head.includes($route.name)")
        +select('filter.committe_head', 'listCommissioners', 'filter.committe_head', '"name"')(item-value="id")
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.commissioner.includes($route.name)")
        +select('filter.commissioner', 'listCommissioners', 'filter.commissioner', '"name"')(item-value="id")
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.experience_required.includes($route.name)")
        +select('filter.experience_required', 'listExperience', 'filter.experience_required', 'nameLang')(item-value="name_ukr")
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.document_property.includes($route.name)")
        +select('filter.document_property', 'listSolution', 'filter.document_property', 'nameLang')(item-value="value")
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.has_crewing.includes($route.name)")
        +select('filter.has_crewing', 'listBooleanOptions', 'filter.has_crewing', 'nameLang')(item-value="value")
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.is_cadet.includes($route.name)")
        +select('filter.is_cadet', 'listCadetType', 'filter.is_cadet', 'nameLang')(item-value="value")
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.has_protocol.includes($route.name)")
        +select('filter.has_protocol', 'listProtocolAvailability', 'filter.has_protocol', 'nameLang')(item-value="value")
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.typeStatement.includes($route.name)")
        +select('typeStatement', 'listStatementTypes', 'filter.typeStatement', 'nameLang')(
          item-value="value"
          @input="chooseTypeStatement"
        )
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.is_new_document.includes($route.name)")
        +select('filter.is_new_document', 'listBooleanOptions', 'filter.is_new_document', 'nameLang')(item-value="value")
      div.col-xs-12.col-sm-12.col-md-6(v-if="viewsQuery.is_continue.includes($route.name)")
        +select('filter.is_continue', 'listTypeStatements', 'filter.is_continue', 'nameLang')(item-value="value")

      div.col-xs-12.col-sm-12.col-md-6
        DateTimePicker(
          ref="customComponentFromDateTime"
          v-if="viewsQuery.payment_from_datetime.includes($route.name)"
          v-model="filter.payment_from_datetime"
          :labelDate="$t('periodPaymentFrom')"
          :maxDate="filter.payment_to_datetime"
          :labelTime="$t('periodTimeFrom')").w-100
      div.col-xs-12.col-sm-12.col-md-6
        DateTimePicker(
          ref="customComponentToDateTime"
          v-if="viewsQuery.payment_to_datetime.includes($route.name)"
          v-model="filter.payment_to_datetime"
          :labelDate="$t('periodPaymentTo')"
          :minDate="filter.payment_from_datetime"
          :labelTime="$t('periodTimeTo')").w-100

  div.d-flex.justify-center
    v-btn(v-if="isVisibilityBtnSearchAndClear" color="success" @click="searchData" :loading="isLoading").mr-3 {{ $t('search') }}
    v-btn(v-if="isVisibilityBtnSearchAndClear" color="secondary" @click="clearFilter") {{ $t('clearFilter') }}
    v-btn(
      v-if="viewsQuery.createReport.includes($route.name)"
      :disabled="allowSaveExcel"
      :loading="isLoadingReport"
      color='primary'
      @click="saveReport"
      ).ml-3 {{$t('btn.createReport')}}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { clearBody, queryString } from '@/mixins/main'
import { initFilterReportSearchV2, STATUSES_DOCUMENT_CONSTANTS, STATUSES_STATEMENT_CONSTANTS, TYPE_DOCUMENTS_CONSTANTS } from '@/configs/constants'
// import { checkAccess } from '@/mixins/permissions'
export default {
  components: {
    DateTimePicker: () => import('@/components/atoms/DateTimePicker')
  },
  props: {
    isLoading: { type: Boolean, default: false },
    pageSize: { type: Number, default: 10 },
    defaultFilter: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      typeStatement: null,
      allowSaveExcel: true,
      listSpecializationsForSpeciality: [],
      interval: null,
      filedParam_price_f1: 'equal',
      filedParam_price_f2: 'equal',
      price_f1: null,
      price_f2: null,
      viewsParams: {
        typeDocument: ['distribution-nostrification-report-group', 'distribution-usual-report-crewing-table'],
        dictionaryType: ['directory-backoffice']
      },
      viewsQuery: {
        manager_search: ['document-powers-of-attorney', 'powers-of-attorney-report', 'nostrification'],
        author: ['submitted-documents-report', 'sqc-report-statement'],
        from_set_verification_at: ['submitted-documents-report'],
        to_set_verification_at: ['submitted-documents-report'],
        is_created_by: ['submitted-documents-report'],
        institution_name: ['history-eti'],
        institution_id: ['history-eti'],
        manager: ['crewing-plus-connect-sailor-report', 'statement-connect-isc-report',
          'statements-service-record-book-isc', 'statement-position-isc-report', 'isc-package-statement',
          'statement-disconnect-isc-report'],
        isListCrewing: ['submitted-documents-report'],
        crewing: ['history-crewing', 'statement-connect-isc-report', 'crewing-plus-connect-sailor-report',
          'statements-service-record-book-isc', 'statement-position-isc-report', 'isc-package-statement'],
        sailor_search: ['certificate-for-members-of-the-sqc-report', 'service-record-book-report',
          'statement-connect-isc-report', 'statement-disconnect-isc-report', 'isc-package-statement', 'medical-report',
          'crewing-plus-connect-sailor-report', 'statement-position-isc-report', 'powers-of-attorney-backoffice',
          'powers-of-attorney-report', 'sign-failed-documents', 'statement-qualification-report'],
        decision: ['certificate-for-members-of-the-sqc-report'],
        direction: ['certificate-for-members-of-the-sqc-report'],
        status_document: ['certificate-for-members-of-the-sqc-report', 'service-record-book-report',
          'statement-qualification-report', 'statement-isc', 'statement-connect-isc-report',
          'statement-disconnect-isc-report', 'isc-package-statement', 'medical-report',
          'statements-service-record-book-isc', 'statement-position-isc-report', 'submitted-documents-report',
          'powers-of-attorney-backoffice', 'powers-of-attorney-report', 'nostrification-report',
          'nostrification', 'new-accounts', 'digitalization-document-isc', 'document-digitization-isc',
          'clarification-of-data', 'eti-statements', 'advance-training-statements', 'sailor-passport-statements',
          'sailor-photo-verification', 'qualification-report', 'digitalization-document-sc', 'srbAll',
          'package-qualification-statement', 'sailor-passport-verification', 'international-passport-verification',
          'education-verification', 'qualification-verification', 'navigator-certificate-verification',
          'service-record-verification', 'line-service-record-verification', 'medical-verification',
          'civil-passport-verification', 'crewing-verification', 'sqc-report-statement', 'education-report-2',
          'certificates-report-2', 'sailor-passport-report'],
        nostrification_document_status: ['nostrification-report', 'nostrification'],
        commissioner_type: ['certificate-for-members-of-the-sqc-report'],
        statement_number: ['certificate-for-members-of-the-sqc-report', 'sqc-report-protocol', 'sqc-report-statement'],
        branch_office: ['certificate-for-members-of-the-sqc-report', 'service-record-book-report',
          'service-record-verification', 'sqc-report-protocol'],
        protokol_number: ['certificate-for-members-of-the-sqc-report'],
        number: ['service-record-book-report', 'qualification-report', 'statement-qualification-report',
          'powers-of-attorney-backoffice', 'powers-of-attorney-report', 'nostrification-report', 'nostrification',
          'eti-statements', 'international-passport-verification', 'medical-report', 'education-verification',
          'qualification-verification', 'navigator-certificate-verification', 'education-report-2',
          'certificates-report-2', 'financial-report-2'],
        sailor_id: ['replacement-qualification-report', 'statements-nostrification', 'eti-statements',
          'advance-training-statements', 'sailor-passport-statements', 'sailor-photo-verification',
          'sailor-passport-verification', 'international-passport-verification', 'education-verification',
          'qualification-verification', 'navigator-certificate-verification', 'service-record-verification',
          'line-service-record-verification', 'medical-verification', 'civil-passport-verification',
          'digitalization-document-sc', 'srbAll', 'eti-payments', 'sailor-passport-report', 'sqc-report-statement',
          'financial-report-2'],
        sailor_name: ['cadet-report', 'qualification-report', 'replacement-qualification-report', 'new-accounts',
          // 'sign-failed-documents',
          'eti-statements', 'advance-training-statements', 'sailor-passport-statements', 'sailor-photo-verification',
          'sailor-passport-verification', 'international-passport-verification', 'education-verification',
          'qualification-verification', 'navigator-certificate-verification', 'service-record-verification',
          'line-service-record-verification', 'medical-verification', 'civil-passport-verification',
          'digitalization-document-sc', 'srbAll', 'eti-payments', 'sqc-report-protocol', 'sqc-report-statement',
          'certificates-report-2', 'sailor-passport-report'],
        sailor_birth: ['cadet-report', 'qualification-report', 'statement-qualification-report', 'sqc-report-protocol',
          'sqc-report-statement', 'certificates-report-2'],
        date_meeting: ['certificate-for-members-of-the-sqc-report', 'eti-statements'],
        from_date_meeting: ['eti-statements', 'sqc-report-statement'],
        to_date_meeting: ['eti-statements', 'sqc-report-statement'],
        date_start: ['service-record-book-report', 'qualification-report', 'education-verification',
          'qualification-verification', 'education-report-2', 'certificates-report-2'],
        date_start__gte: ['service-record-book-report', 'qualification-report', 'certificates-report-2'],
        // 'distribution-usual-report-crewing-table'],
        date_start__lte: ['service-record-book-report', 'qualification-report', 'certificates-report-2'],
        // 'distribution-usual-report-crewing-table'],
        date_issued: [],
        date_end: ['qualification-report', 'education-verification', 'qualification-verification',
          'education-report-2', 'certificates-report-2'],
        date_end__gte: ['certificates-report-2'],
        date_end_lte: ['certificates-report-2'],
        createReport: ['service-record-book-report', 'existing-doc-verification', 'statement-qualification-report',
          'medical-report', 'replacement-qualification-report', 'statement-position-isc-report',
          'submitted-documents-report', 'powers-of-attorney-report', 'nostrification-report',
          'debtor-report', 'debtor-report-crewing', 'document-digitization-isc',
          'distribution-nostrification-report-sailor', 'user-verification-statistics',
          'existing-doc-verification-statistics', 'sailor-photo-verification-statistics',
          'foreign-passport-verification-statistics', 'sailor-passport-verification-statistics',
          'statistics-citizen-passport-verification', 'statistics-isc-user-verification',
          'statistics-isc-user-verification', 'crewing-connect-company-report', 'crewing-connect-manager-report',
          'crewing-connect-sailor-report'
        ],
        issued_by: ['service-record-book-report'],
        event_date: ['debtor-report'],
        from_event_date: ['debtor-report'],
        to_event_date: ['debtor-report'],
        date_not_bought: ['debtor-report'],
        date_create: ['user-verification', 'existing-doc-verification', 'sailor-photo-verification',
          'service-record-verification', 'line-in-service-record-verification', 'education-verification',
          'medical-certificate-verification', 'qualification-verification', 'navigator-verification',
          'foreign-passport-verification', 'sailor-passport-verification', 'cadet-report', 'qualification-report',
          'statement-isc', 'crewing-statement', 'statement-connect-isc-report', 'statement-disconnect-isc-report',
          'isc-package-statement', 'medical-report', 'replacement-qualification-report',
          'crewing-plus-connect-sailor-report', 'statements-service-record-book-isc', 'statement-position-isc-report',
          'powers-of-attorney-backoffice', 'powers-of-attorney-report', 'nostrification-report', 'nostrification',
          'new-accounts', 'debtor-report', 'digitalization-document-isc', 'document-digitization-isc',
          'distribution-nostrification-report-group', 'clarification-of-data', 'user-verification-statistics',
          'existing-doc-verification-statistics', 'sailor-photo-verification-statistics',
          'service-record-verification-statistics', 'line-in-service-record-verification-statistics',
          'education-verification-statistics', 'medical-certificate-verification-statistics',
          'qualification-verification-statistics', 'navigator-verification-statistics',
          'foreign-passport-verification-statistics', 'sailor-passport-verification-statistics',
          'statistics-citizen-passport-verification', 'eti-statements', 'advance-training-statements',
          'submitted-documents-report', 'sailor-passport-statements', 'sailor-photo-verification',
          'international-passport-verification', 'navigator-certificate-verification',
          'line-service-record-verification', 'medical-verification', 'civil-passport-verification',
          'digitalization-document-sc', 'srbAll', 'package-qualification-statement', 'history-user',
          'crewing-verification', 'statistics-isc-user-verification', 'digitization-documents',
          'digitization-statements', 'sqc-report-protocol', 'sqc-report-statement', 'education-report-2',
          'certificates-report-2', 'sailor-passport-report', 'atc-distribution-institution-report',
          'crewing-connect-company-report', 'financial-report-2', 'distribution-usual-report-crewing-table'],
        from_date: ['user-verification', 'existing-doc-verification', 'sailor-photo-verification',
          'line-in-service-record-verification', 'education-verification', 'medical-certificate-verification',
          'qualification-verification', 'navigator-verification', 'foreign-passport-verification',
          'sailor-passport-verification', 'cadet-report', 'qualification-report', 'statement-qualification-report',
          'crewing-statement', 'statement-connect-isc-report', 'statement-disconnect-isc-report', 'medical-report',
          'replacement-qualification-report', 'crewing-plus-connect-sailor-report',
          'statements-service-record-book-isc', 'statement-position-isc-report', 'isc-package-statement',
          'submitted-documents-report', 'powers-of-attorney-backoffice', 'powers-of-attorney-report',
          'nostrification-report', 'nostrification', 'new-accounts', 'debtor-report', 'digitalization-document-isc',
          'document-digitization-isc', 'distribution-nostrification-report-group', 'clarification-of-data',
          'eti-statements', 'advance-training-statements', 'service-record-verification',
          'sailor-passport-statements', 'sailor-photo-verification', 'international-passport-verification',
          'navigator-certificate-verification', 'line-service-record-verification', 'medical-verification',
          'civil-passport-verification', 'digitalization-document-sc', 'srbAll', 'history-user',
          'crewing-verification', 'digitization-documents', 'digitization-statements', 'sqc-report-protocol',
          'sqc-report-statement', 'education-report-2', 'certificates-report-2', 'sailor-passport-report',
          'atc-distribution-institution-report', 'crewing-connect-company-report', 'financial-report-2',
          'distribution-usual-report-crewing-table'],
        to_date: ['user-verification', 'existing-doc-verification', 'sailor-photo-verification',
          'line-in-service-record-verification', 'education-verification', 'medical-certificate-verification',
          'qualification-verification', 'navigator-verification', 'foreign-passport-verification',
          'sailor-passport-verification', 'cadet-report', 'qualification-report', 'statement-qualification-report',
          'crewing-statement', 'statement-connect-isc-report', 'statement-disconnect-isc-report', 'medical-report',
          'replacement-qualification-report', 'crewing-plus-connect-sailor-report',
          'statements-service-record-book-isc', 'statement-position-isc-report', 'isc-package-statement',
          'submitted-documents-report', 'powers-of-attorney-backoffice', 'powers-of-attorney-report',
          'nostrification-report', 'nostrification', 'new-accounts', 'debtor-report', 'digitalization-document-isc',
          'document-digitization-isc', 'distribution-nostrification-report-group', 'clarification-of-data',
          'eti-statements', 'advance-training-statements', 'service-record-verification',
          'sailor-passport-statements', 'sailor-photo-verification', 'international-passport-verification',
          'navigator-certificate-verification', 'line-service-record-verification', 'medical-verification',
          'civil-passport-verification', 'digitalization-document-sc', 'srbAll', 'history-user',
          'crewing-verification', 'digitization-documents', 'digitization-statements', 'sqc-report-protocol',
          'sqc-report-statement', 'education-report-2', 'certificates-report-2', 'sailor-passport-report',
          'atc-distribution-institution-report', 'crewing-connect-company-report', 'financial-report-2',
          'distribution-usual-report-crewing-table'],
        from_date_create: ['user-verification-statistics', 'statistics-isc-user-verification', 'existing-doc-verification-statistics',
          'sailor-photo-verification-statistics', 'service-record-verification-statistics',
          'line-in-service-record-verification-statistics', 'education-verification-statistics',
          'medical-certificate-verification-statistics', 'qualification-verification-statistics',
          'navigator-verification-statistics', 'foreign-passport-verification-statistics',
          'sailor-passport-verification-statistics', 'statistics-citizen-passport-verification',
          'package-qualification-statement', 'statement-isc'],
        to_date_create: ['user-verification-statistics', 'existing-doc-verification-statistics',
          'sailor-photo-verification-statistics', 'service-record-verification-statistics',
          'line-in-service-record-verification-statistics', 'statistics-isc-user-verification',
          'education-verification-statistics', 'medical-certificate-verification-statistics',
          'qualification-verification-statistics', 'navigator-verification-statistics',
          'foreign-passport-verification-statistics', 'sailor-passport-verification-statistics',
          'statistics-citizen-passport-verification', 'package-qualification-statement', 'statement-isc'],
        date_transfer: ['history-crewing'],
        from_date_transfer: ['history-crewing'],
        to_date_transfer: ['history-crewing'],
        date_processing: ['user-verification-statistics', 'existing-doc-verification-statistics',
          'sailor-photo-verification-statistics', 'service-record-verification-statistics',
          'line-in-service-record-verification-statistics', 'education-verification-statistics',
          'medical-certificate-verification-statistics', 'qualification-verification-statistics',
          'navigator-verification-statistics', 'foreign-passport-verification-statistics',
          'sailor-passport-verification-statistics', 'statistics-citizen-passport-verification',
          'statistics-isc-user-verification'],
        from_date_processing: ['user-verification-statistics', 'existing-doc-verification-statistics',
          'sailor-photo-verification-statistics', 'service-record-verification-statistics',
          'line-in-service-record-verification-statistics', 'education-verification-statistics',
          'medical-certificate-verification-statistics', 'qualification-verification-statistics',
          'navigator-verification-statistics', 'foreign-passport-verification-statistics',
          'sailor-passport-verification-statistics', 'statistics-citizen-passport-verification',
          'statistics-isc-user-verification'],
        to_date_processing: ['user-verification-statistics', 'existing-doc-verification-statistics',
          'sailor-photo-verification-statistics', 'service-record-verification-statistics',
          'line-in-service-record-verification-statistics', 'education-verification-statistics',
          'medical-certificate-verification-statistics', 'qualification-verification-statistics',
          'navigator-verification-statistics', 'foreign-passport-verification-statistics',
          'sailor-passport-verification-statistics', 'statistics-citizen-passport-verification',
          'statistics-isc-user-verification'],
        faculty: ['cadet-report'],
        education_institution: ['cadet-report', 'eti-statements', 'advance-training-statements',
          'education-verification', 'eti-payments', 'education-report-2', 'certificates-report-2'],
        has_statement: ['cadet-report'],
        passed_educ_exam: ['cadet-report'],
        educ_with_dkk: ['cadet-report'],
        rank: ['qualification-report', 'statement-qualification-report', 'replacement-qualification-report',
          'statement-position-isc-report', 'isc-package-statement', 'qualification-verification',
          'package-qualification-statement', 'sqc-report-protocol', 'sqc-report-statement'],
        position: ['qualification-report', 'statement-qualification-report', 'replacement-qualification-report',
          'statement-position-isc-report', 'isc-package-statement', 'qualification-verification',
          'package-qualification-statement', 'sqc-report-protocol', 'sqc-report-statement'],
        port: ['qualification-report', 'sailor-passport-statements', 'sailor-passport-verification',
          'qualification-verification', 'sailor-passport-report'],
        country: ['qualification-report', 'sailor-passport-verification', 'qualification-verification',
          'sailor-passport-report'],
        type_document: ['qualification-report', 'replacement-qualification-report', 'digitalization-document-isc',
          'document-digitization-isc', 'clarification-of-data', 'education-verification',
          'qualification-verification', 'digitalization-document-sc', 'package-qualification-statement',
          'education-report-2', 'financial-report-2'],
        created_at: ['statement-qualification-report'],
        type_user: ['account-management'],
        id: ['account-management', 'crewing-groups-backoffice', 'crewing-companies-backoffice', 'courses-backoffice',
          'directory-backoffice', 'list-eti-backoffice', 'vnz-backoffice'],
        unsigned_reason: ['sign-failed-documents'],
        content_type: ['sign-failed-documents', 'submitted-documents-report', 'digitization-documents',
          'digitization-statements'],
        type_exam: ['debtor-report', 'financial-report-2', 'distribution-usual-report-crewing-table'],
        item_status: ['debtor-report', 'distribution-usual-report-crewing-table'],
        payment_datetime: ['distribution-nostrification-report-group'],
        payment_from_datetime: ['distribution-nostrification-report-group', 'debtor-report', 'distribution-usual-report-crewing-table'],
        payment_to_datetime: ['distribution-nostrification-report-group', 'debtor-report', 'distribution-usual-report-crewing-table'],
        crewing_group: ['distribution-nostrification-report-group', 'distribution-usual-report-crewing-table'],
        crewing_company: ['crewing-groups-backoffice'],
        course: ['eti-statements', 'eti-payments', 'certificates-report-2'],
        from_date_end_meeting: ['eti-statements', 'advance-training-statements'],
        to_date_end_meeting: ['eti-statements', 'advance-training-statements'],
        is_payed: ['eti-statements', 'advance-training-statements', 'sailor-passport-statements', 'srbAll',
          'digitization-statements'],
        level_qualification: ['advance-training-statements'],
        serial: ['education-verification', 'civil-passport-verification', 'education-report-2'],
        registry_number: ['education-verification', 'education-report-2'],
        extent: ['education-verification', 'education-report-2'],
        qualification: ['education-verification', 'education-report-2'],
        speciality: ['education-verification', 'education-report-2'],
        specialization: ['education-verification', 'education-report-2'],
        type_delivery: ['srbAll'],
        user: ['history-user'],
        sailor_key: ['history-user'],
        company_name: ['crewing-verification'],
        pay_date: ['eti-payments'],
        from_pay_date: ['eti-payments'],
        to_pay_date: ['eti-payments'],
        // Report keys with old endpoints
        protocol_number: ['sqc-report-protocol'],
        protocol_year: ['sqc-report-protocol'],
        direction_abbr: ['sqc-report-protocol', 'sqc-report-statement'],
        statement_branch_office: ['sqc-report-protocol'],
        committe_head: ['sqc-report-protocol'],
        commissioner: ['sqc-report-protocol'],
        experience_required: ['sqc-report-protocol', 'sqc-report-statement'],
        document_property: ['sqc-report-protocol'],
        has_crewing: ['sqc-report-protocol', 'sqc-report-statement', 'sailor-passport-report'],
        statement_year: ['sqc-report-protocol', 'sqc-report-statement'],
        is_cadet: ['sqc-report-protocol', 'sqc-report-statement'],
        typeStatement: ['sqc-report-protocol', 'sqc-report-statement'],
        has_protocol: ['sqc-report-statement'],
        is_new_document: ['sailor-passport-report'],
        is_continue: ['sailor-passport-report'],
        // Finance
        price_f1: ['financial-report-2'],
        price_f2: ['financial-report-2']
      },
      filter: initFilterReportSearchV2(),
      params: {
        typeDocument: this.viewsParams?.typeDocument?.includes(this.$route.name) ? this.listDistribution.find(el => el.value === this.$route.params.typeDocument) || 'mes' : null,
        dictionaryType: this.viewsParams?.dictionaryType?.includes(this.$route.name) ? this.$route.params.dictionaryType || 'LevelQualification' : null
      },
      clearBody,
      multiKeys: ['status_document', 'crewing', 'nostrification_document_status', 'crewing_group', 'course',
        'is_payed', 'level_qualification', 'extent', 'qualification', 'speciality', 'specialization', 'is_created_by',
        'education_institution', 'faculty', 'country', 'rank', 'position', 'port', 'type_document', 'unsigned_reason',
        'content_type', 'author']
    }
  },
  computed: {
    ...mapState({
      decisions: state => state.directory.decision,
      branch_offices: state => state.directory.affiliate,
      directions: state => state.directory.direction,
      commissioner_types: state => state.directory.commissioner_types,
      statuses_document: state => state.directory.statuses,
      nameLang: state => (state.main.lang === 'en' ? 'name_eng' : 'name_ukr'),
      valueLang: state => (state.main.lang === 'en' ? 'value_eng' : 'value_ukr'),
      isLoadingReport: state => state.report.isLoadingReport,
      issuedByList: state => state.directory.issuedByList,
      listEducationInstitutions: state => state.directory.institution,
      listFaculties: state => state.directory.faculties,
      mappingApplicationAvailability: state => state.directory.mappingApplicationAvailability,
      mappingResultsEQC: state => state.directory.mappingResultsEQC,
      mappingEducExamPass: state => state.directory.mappingEducExamPass,
      listCountries: state => state.directory.country,
      listRanks: state => state.directory.ranks,
      listPorts: state => state.directory.ports,
      listQualificationDocumentTypes: state => state.directory.typeDocQualification,
      userTypes: state => state.directory.userTypes,
      unsignedReasons: state => state.directory.unsignedReasons,
      allDocumentTypes: state => state.directory.allDocumentTypes,
      listCrewingCompanies: state => state.crewing.crewingCompanies.results,
      userList: state => state.user.userList.results,
      listCreatedBy: state => state.directory.listCreatedBy,
      listTypeExam: state => state.directory.listTypeExam,
      listStatusesDocumentPositions: state => state.directory.listStatusesDocumentPositions,
      verificationDocumentTypes: state => state.directory.verificationDocumentTypes,
      listDistributionType: state => state.directory.listDistributionType,
      listGroup: state => state.directory.crewingGroups,
      listDictionaryType: state => state.directory.listDictionaryType,
      listCourses: state => state.directory.courses,
      listPaymentStatus: state => state.directory.paymentStatus,
      listQualificationLevel: state => state.directory.qualificationLevels,
      listEducationDocumentTypes: state => state.directory.typeDoc,
      listExtents: state => state.directory.extent,
      listProfessions: state => state.directory.profession,
      listSpecializations: state => state.directory.specialization,
      listTypeDelivery: state => state.directory.deliveryTypeList,
      listUser: state => state.user.userList,
      listSailor: state => state.sailor.sailors,
      listEducationTraining: state => state.directory.educationTraining,
      listWay: state => state.directory.listWay,
      listCommissioners: state => state.directory.commission,
      listExperience: state => state.directory.listExperience,
      listSolution: state => state.directory.listSolution,
      listBooleanOptions: state => state.directory.listBooleanOptions,
      listCadetType: state => state.directory.listCadetType,
      listTypeStatements: state => state.directory.listStatementTypes,
      listStatementTypes: state => state.directory.typeStatements,
      listProtocolAvailability: state => state.directory.listProtocolAvailability,
      listFormParams: state => state.directory.listFormParams,
      listAccrualDocumentTypes: state => state.directory.allAccrualTypeDoc,
      listDistributionTypeUsual: state => state.directory.listDistributionTypeUsual,
      listDocumentStatus: state => state.directory.listDocumentStatus
    }),
    ...mapGetters(['positionsByRankID', 'statusChoose', 'statusById', 'getDirectoryObject']),
    listInstitution () {
      switch (this.$route.name) {
        case 'certificates-report-2':
        case 'eti-payments': return this.listEducationTraining
        default: return this.listEducationInstitutions
      }
    },
    dinamicListQualificationLevel () {
      switch (this.$route.name) {
        case 'education-verification':
          return this.listQualificationLevel
        default: return this.listQualificationLevel.filter(qualification => qualification.type_NZ === 2)
      }
    },
    nostrificationStatusesList () {
      let listStatusNostrification = [
        STATUSES_DOCUMENT_CONSTANTS.rejectByTransator, STATUSES_DOCUMENT_CONSTANTS.rejectByNostrification,
        STATUSES_DOCUMENT_CONSTANTS.waitingForTranslation, STATUSES_DOCUMENT_CONSTANTS.documentCreated,
        STATUSES_DOCUMENT_CONSTANTS.waitingSignMon, STATUSES_DOCUMENT_CONSTANTS.waitingForNostrification,
        STATUSES_DOCUMENT_CONSTANTS.rejectedByNostrificator, STATUSES_DOCUMENT_CONSTANTS.inProgressNostrification,
        STATUSES_DOCUMENT_CONSTANTS.waitingNostrificatorPayment, STATUSES_DOCUMENT_CONSTANTS.rejectedByTranslator,
        STATUSES_DOCUMENT_CONSTANTS.inProgressTranslation, STATUSES_DOCUMENT_CONSTANTS.waitingTranslatorPayment,
        STATUSES_DOCUMENT_CONSTANTS.waitingSignTranslator ]
      return this.statuses_document.filter(item => listStatusNostrification.includes(item.id))
    },
    statusesList () {
      let statusesList = []
      let statuses = []
      switch (this.$route.name) {
        case 'statement-isc':
          return this.statuses_document.filter((item) => [34, 41, 36, 83].includes(item.id))
        case 'certificate-for-members-of-the-sqc-report':
          return this.statuses_document.filter((item) => [89, 25, 24, 23].includes(item.id))
        case 'qualification-report':
          statusesList = this.statuses_document.filter((item) =>
            [20, 18, 17, 8, 19, 21, 33, 37, 7, 60, 86, 118, 121, 12, 42, 74].includes(item.id)
          )
          // if (checkAccess('backOffice')) statusesList.push(this.statusById(124))
          return statusesList
        case 'statement-connect-isc-report':
          return this.statuses_document.filter((item) => [67, 68, 69, 82, 83, 86].includes(item.id))
        case 'statement-disconnect-isc-report':
          return this.statuses_document.filter((item) => [67, 68, 69, 82, 83, 86].includes(item.id))
        case 'statement-qualification-report':
          return this.statuses_document.filter((item) => [23, 24, 25, 42].includes(item.id))
        case 'service-record-book-report':
          statuses = this.statuses_document.filter((item) => [1, 2, 6, 13, 14, 34, 86, 89].includes(item.id))
          statuses.push({ id: '74,122', name_ukr: 'Чернетка' })
          return statuses
        case 'isc-package-statement':
          return this.statuses_document.filter((item) => [23, 25, 47, 73, 139].includes(item.id))
        case 'medical-report':
          return this.statuses_document.filter((item) => [12, 75, 76, 77].includes(item.id))
        case 'statement-position-isc-report':
        case 'statements-service-record-book-isc':
          return this.statuses_document.filter((item) => [23, 25, 47, 73, 139].includes(item.id))
        case 'submitted-documents-report':
          const statusesSubmittedDocuments = {
            valid: { id: '19,9,2,70', name_ukr: 'Дійсний', name_eng: 'Valid' },
            rejected: { id: '10,71', name_ukr: 'Відхилено', name_eng: 'Rejected' },
            invalid: { id: '37,13', name_ukr: 'Анульований', name_eng: 'Invalid' },
            inProcessing: { id: '21,5,14,72', name_ukr: 'В обробці', name_eng: 'In processing' },
            expired: { id: '37,13', name_ukr: 'термін дії закінчився', name_eng: 'Expired' }
          }
          statusesList.push(this.statuses_document.find((item) => item.id === 34))
          statusesList.push(this.statuses_document.find((item) => item.id === 74))
          Object.entries(statusesSubmittedDocuments).forEach(([key, value]) => statusesList.push(value))
          statusesList.push({ id: 'other', name_ukr: 'Інші', name_eng: 'Other' })
          return statusesList
        case 'powers-of-attorney-backoffice':
        case 'powers-of-attorney-report':
          return this.statuses_document.filter((item) => [12, 67, 68, 73, 122].includes(item.id))
        case 'nostrification-report':
          return this.statuses_document.filter((item) => [STATUSES_DOCUMENT_CONSTANTS.rejectedByCrewing, STATUSES_STATEMENT_CONSTANTS.inProcess, STATUSES_STATEMENT_CONSTANTS.documentCreated].includes(item.id))
        case 'nostrification':
          return this.statuses_document.filter((item) => [STATUSES_STATEMENT_CONSTANTS.statementCreated, STATUSES_STATEMENT_CONSTANTS.inProcess, STATUSES_STATEMENT_CONSTANTS.documentCreated].includes(item.id))
        case 'new-accounts':
          return this.statuses_document.filter((item) => [34, 36, 41].includes(item.id))
        case 'digitalization-document-isc':
        case 'document-digitization-isc':
          return this.statuses_document.filter((item) => [19, 21, 34, 37, 42, 121].includes(item.id))
        case 'clarification-of-data':
          return this.statuses_document.filter((item) => [23, 24, 42].includes(item.id))
        case 'eti-statements':
          return this.statuses_document.filter((item) => [62, 63, 85, 91, 115, 116, 120, 125, 126].includes(item.id))
        case 'sailor-passport-statements':
          return this.statuses_document.filter((item) => [70, 71, 72].includes(item.id))
        case 'advance-training-statements':
          return this.statuses_document.filter((item) => [78, 79, 80, 84, 86, 140, 141].includes(item.id))
        case 'sailor-photo-verification':
          return this.statuses_document.filter((item) => [34, 36, 41].includes(item.id))
        case 'sailor-passport-verification':
        case 'international-passport-verification':
        case 'education-verification':
        case 'qualification-verification':
        case 'navigator-certificate-verification':
        case 'service-record-verification':
        case 'line-service-record-verification':
        case 'medical-verification':
        case 'civil-passport-verification':
          return this.statuses_document.filter((item) => [34, 42, 74].includes(item.id))
        case 'srbAll':
          return this.statuses_document.filter((item) => [42, 47, 48, 49, 87].includes(item.id))
        case 'digitalization-document-sc':
          return this.statuses_document.filter((item) => [7, 8, 17, 18, 19, 20, 21, 33, 37, 42, 114, 121].includes(item.id))
        case 'package-qualification-statement':
          statuses = this.statuses_document.filter((item) => [24, 25, 117, 119].includes(item.id))
          statuses.push({ id: '73,84', name_ukr: 'Анульовано', name_eng: 'Canceled' })
          statuses.push({ id: '16,23', name_ukr: 'Відхилено', name_eng: 'Rejected' })
          statuses.push({ id: '74,122', name_ukr: 'Чернетка', name_eng: 'Draft' })
          return statuses
        case 'crewing-verification':
          return this.statuses_document.filter((item) => [34, 92, 93, 94, 95].includes(item.id))
        case 'education-report-2':
          return this.statuses_document.filter((item) => [1, 2, 6, 7, 13, 14, 34, 74, 86].includes(item.id))
        case 'sqc-repost-statement':
          return this.statuses_document.filter((item) => [12, 16, 23, 24, 25, 42, 60, 73, 74, 86, 117, 118, 119, 121,
            124, 143, 144, 145, 146, 147, 148, 149].includes(item.id))
        case 'certificates-report-2':
          return this.statuses_document.filter((item) => [7, 17, 18, 19, 21, 37].includes(item.id))
        case 'sailor-passport-report':
          return this.statuses_document.filter((item) => [7, 8, 17, 18, 19, 20, 21, 33, 37].includes(item.id))
        default:
          return []
      }
    },
    listTypeDocument () {
      const { QUALIFICATION, SERVICE_RECORD, MEDICAL_CERTIFICATE, EXPERIENCE_IN_SERVICE_RECORD, EXPERIENCE_CERTIFICATE,
        EXPERIENCE_HISTORY, CERTIFICATE_OF_NAVIGATOR, PROTOCOL_SQC, CERTIFICATE_ETI, PROOF_OF_DIPLOMA
      } = TYPE_DOCUMENTS_CONSTANTS
      switch (this.$route.name) {
        case 'digitalization-document-isc':
        case 'document-digitization-isc':
          return this.verificationDocumentTypes.filter(item => [
            QUALIFICATION, SERVICE_RECORD, MEDICAL_CERTIFICATE, EXPERIENCE_IN_SERVICE_RECORD, EXPERIENCE_CERTIFICATE,
            EXPERIENCE_HISTORY, PROOF_OF_DIPLOMA].includes(item.id))
        case 'digitalization-document-sc': return this.verificationDocumentTypes.filter(el => ![
          CERTIFICATE_ETI, PROTOCOL_SQC, CERTIFICATE_OF_NAVIGATOR
        ].includes(el.id))
        case 'clarification-of-data':
          return this.verificationDocumentTypes.filter(item => ![CERTIFICATE_OF_NAVIGATOR, CERTIFICATE_ETI,
            PROTOCOL_SQC].includes(item.id))
        case 'education-report-2':
        case 'education-verification':
          return this.listEducationDocumentTypes
        case 'financial-report-2':
          return this.listAccrualDocumentTypes.map(el => {
            el[this.nameLang] = el.value
            return el
          })
        default: return this.listQualificationDocumentTypes
      }
    },
    isMultiStatus () {
      return [
        'qualification-report', 'statement-qualification-report', 'statements-service-record-book-isc',
        'statement-position-isc-report', 'submitted-documents-report', 'new-accounts', 'clarification-of-data',
        'sailor-passport-verification', 'international-passport-verification', 'education-verification',
        'qualification-verification', 'navigator-certificate-verification', 'service-record-verification',
        'line-service-record-verification', 'medical-verification', 'civil-passport-verification',
        'digitalization-document-sc', 'document-digitization-isc', 'srbAll', 'crewing-verification'].includes(this.$route.name)
    },
    isVisibilitySearch () {
      let allNameRoute = []
      Object.keys(this.viewsQuery).map((key) => {
        allNameRoute.push(this.viewsQuery[key])
      })
      Object.keys(this.viewsParams).map((key) => {
        allNameRoute.push(this.viewsParams[key])
      })
      return allNameRoute.flat(2).includes(this.$route.name)
    },
    isVisibilityBtnSearchAndClear () {
      let allNameRoute = []
      Object.keys(this.viewsQuery).map((key) => {
        if (key !== 'createReport') allNameRoute.push(this.viewsQuery[key])
      })
      Object.keys(this.viewsParams).map((key) => {
        if (key !== 'createReport') allNameRoute.push(this.viewsParams[key])
      })
      return allNameRoute.flat(2).includes(this.$route.name)
    },
    signFailedContentTypesList () {
      switch (this.$route.name) {
        case 'digitization-documents':
        case 'digitization-statements':
          return this.allDocumentTypes.filter(item => ['sailorpassport', 'passport', 'foreignpassport', 'education',
            'qualificationdocument', 'proofofworkdiploma', 'certificateofnavigator', 'certificateeti', 'servicerecord',
            'medicalcertificate', 'lineinservicerecord', 'lineinservicerecord', 'packetitem'
          ].includes(item.content_type))
        case 'submitted-documents-report':
          return this.allDocumentTypes.filter(item => [
            'servicerecord', 'lineinservicerecord', 'experienceline', 'medicalcertificate', 'higheducation',
            'qualify_worker', 'upper_qualify', 'worker_qualify', 'sailorpassport', 'qualificationdocument',
            'proofofworkdiploma', 'foreignpassport'].includes(item.content_type))
        default:
          return this.allDocumentTypes.filter(item => ['qualificationdocument', 'proofofworkdiploma',
            'servicerecord'].includes(item.content_type))
      }
    },
    labelDateCreate () {
      switch (this.$route.name) {
        case 'distribution-nostrification-report-group':
        case 'clarification-of-data':
        case 'eti-statements':
        case 'sailor-passport-statements':
        case 'advance-training-statements':
        case 'statement-isc':
          return 'filter.date_create_statement'
        case 'user-verification-statistics':
        case 'statistics-isc-user-verification':
        case 'existing-doc-verification-statistics':
        case 'sailor-photo-verification-statistics':
        case 'service-record-verification-statistics':
        case 'line-in-service-record-verification-statistics':
        case 'education-verification-statistics':
        case 'medical-certificate-verification-statistics':
        case 'qualification-verification-statistics':
        case 'navigator-verification-statistics':
        case 'foreign-passport-verification-statistics':
        case 'sailor-passport-verification-statistics':
        case 'statistics-citizen-passport-verification':
          return 'filter.date_create_verification'
        case 'education-report-2':
        case 'certificates-report-2':
          return 'filter.exact_date_create'
        default: return 'filter.date_create'
      }
    },
    labelDateCreateFrom () {
      switch (this.$route.name) {
        case 'distribution-nostrification-report-group':
        case 'clarification-of-data':
        case 'eti-statements':
        case 'sailor-passport-statements':
        case 'advance-training-statements':
        case 'statement-isc':
          return 'filter.from_date_statement'
        case 'user-verification-statistics':
        case 'statistics-isc-user-verification':
        case 'existing-doc-verification-statistics':
        case 'sailor-photo-verification-statistics':
        case 'service-record-verification-statistics':
        case 'line-in-service-record-verification-statistics':
        case 'education-verification-statistics':
        case 'medical-certificate-verification-statistics':
        case 'qualification-verification-statistics':
        case 'navigator-verification-statistics':
        case 'foreign-passport-verification-statistics':
        case 'sailor-passport-verification-statistics':
        case 'statistics-citizen-passport-verification':
          return 'filter.from_date_verification'
        case 'education-report-2':
        case 'certificates-report-2':
          return 'filter.from_date_create'
        default: return 'filter.from_date'
      }
    },
    labelDateCreateTo () {
      switch (this.$route.name) {
        case 'distribution-nostrification-report-group':
        case 'clarification-of-data':
        case 'eti-statements':
        case 'sailor-passport-statements':
        case 'advance-training-statements':
        case 'statement-isc':
          return 'filter.to_date_statement'
        case 'user-verification-statistics':
        case 'statistics-isc-user-verification':
        case 'existing-doc-verification-statistics':
        case 'sailor-photo-verification-statistics':
        case 'service-record-verification-statistics':
        case 'line-in-service-record-verification-statistics':
        case 'education-verification-statistics':
        case 'medical-certificate-verification-statistics':
        case 'qualification-verification-statistics':
        case 'navigator-verification-statistics':
        case 'foreign-passport-verification-statistics':
        case 'sailor-passport-verification-statistics':
        case 'statistics-citizen-passport-verification':
          return 'filter.to_date_verification'
        case 'education-report-2':
        case 'certificates-report-2':
          return 'filter.to_date_create'
        default: return 'filter.to_date'
      }
    },
    labelDateProcessing () {
      switch (this.$route.name) {
        case 'user-verification-statistics':
        case 'statistics-isc-user-verification':
        case 'existing-doc-verification-statistics':
        case 'sailor-photo-verification-statistics':
        case 'service-record-verification-statistics':
        case 'line-in-service-record-verification-statistics':
        case 'education-verification-statistics':
        case 'medical-certificate-verification-statistics':
        case 'qualification-verification-statistics':
        case 'navigator-verification-statistics':
        case 'foreign-passport-verification-statistics':
        case 'sailor-passport-verification-statistics':
        case 'statistics-citizen-passport-verification':
          return 'filter.date_processing_verification'
        default: return 'filter.date_processing'
      }
    },
    labelDateProcessingFrom () {
      switch (this.$route.name) {
        case 'user-verification-statistics':
        case 'statistics-isc-user-verification':
        case 'existing-doc-verification-statistics':
        case 'sailor-photo-verification-statistics':
        case 'service-record-verification-statistics':
        case 'line-in-service-record-verification-statistics':
        case 'education-verification-statistics':
        case 'medical-certificate-verification-statistics':
        case 'qualification-verification-statistics':
        case 'navigator-verification-statistics':
        case 'foreign-passport-verification-statistics':
        case 'sailor-passport-verification-statistics':
        case 'statistics-citizen-passport-verification':
          return 'filter.from_date_processing_verification'
        default: return 'filter.from_date_processing'
      }
    },
    labelDateProcessingTo () {
      switch (this.$route.name) {
        case 'user-verification-statistics':
        case 'statistics-isc-user-verification':
        case 'existing-doc-verification-statistics':
        case 'sailor-photo-verification-statistics':
        case 'service-record-verification-statistics':
        case 'line-in-service-record-verification-statistics':
        case 'education-verification-statistics':
        case 'medical-certificate-verification-statistics':
        case 'qualification-verification-statistics':
        case 'navigator-verification-statistics':
        case 'foreign-passport-verification-statistics':
        case 'sailor-passport-verification-statistics':
        case 'statistics-citizen-passport-verification':
          return 'filter.to_date_processing_verification'
        default: return 'filter.to_date_processing'
      }
    },
    labelID () {
      switch (this.$route.name) {
        case 'crewing-groups-backoffice': return 'filter.group_id'
        case 'crewing-companies-backoffice': return 'filter.crewing_company'
        case 'vnz-backoffice': return 'filter.vnz_id'
        case 'list-eti-backoffice':
        case 'courses-backoffice': return 'filter.institution_id'
        default: return 'id'
      }
    },
    labelPayDate () {
      switch (this.$route.name) {
        default: return 'filter.payDate'
      }
    },
    labelFromPayDate () {
      switch (this.$route.name) {
        default: return 'filter.fromPayDate'
      }
    },
    labelToPayDate () {
      switch (this.$route.name) {
        default: return 'filter.toPayDate'
      }
    },
    listDistribution () {
      switch (this.$route.name) {
        case 'distribution-usual-report-crewing-table':
          return this.listDistributionTypeUsual
        default: return this.listDistributionType
      }
    },
    listStatusesDocument () {
      switch (this.$route.name) {
        case 'distribution-usual-report-crewing-table':
          return this.listDocumentStatus
        default:
          return this.listStatusesDocumentPositions
      }
    }
  },
  watch: {
    pageSize (val) {
      this.filter.page_size = val.toString()
    },
    filter: {
      handler () {
        let cloneFilter = { ...this.filter }
        let deleteKeys = ['page', 'page_size']
        deleteKeys.forEach((item) => delete cloneFilter[item])
        this.allowSaveExcel = !Object.values(cloneFilter).some((item) => item !== null && item !== '')
      },
      deep: true
    }
  },
  mounted () {
    const { typeDocument } = this.$route.params
    // eslint-disable-next-line camelcase
    const { with_exp, is_cadet } = this.$route.query
    // #TODO
    if (typeDocument) {
      // eslint-disable-next-line camelcase
      this.params.typeDocument = this.listDistribution.filter(el => el.with_exp === with_exp || el.is_cadet === is_cadet)
        .find(el => el.value === typeDocument)
    }
    this.setQuery({ ...this.$route.query, ...this.defaultFilter })
    const { query } = this.$route
    this.setFilter(query)
  },
  methods: {
    ...mapActions(['getUsers', 'getListSailor']),
    setQuery (query, params = {}) {
      const copyRouterParams = { ...this.$route.params }
      Object.keys(this.viewsParams).forEach(key => {
        Object.keys(copyRouterParams).forEach(keyParams => {
          if (key !== keyParams) delete copyRouterParams[keyParams]
        })
      })
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query) ||
      JSON.stringify(params) !== JSON.stringify(copyRouterParams)) {
        /**
         * #TODO
         */
        if (params.typeDocument) {
          typeof params.typeDocument.with_exp === 'boolean'
            ? (query.with_exp = params.typeDocument.with_exp)
            : delete query.with_exp
          typeof params.typeDocument.is_cadet === 'boolean'
            ? (query.is_cadet = params.typeDocument.is_cadet)
            : delete query.is_cadet
          params.typeDocument = params.typeDocument.value
        }
        this.$router.replace({ query, params })
      }
    },
    setFilter (query) {
      if (Object.keys(query).length) {
        Object.entries(query).forEach(([key, value]) => {
          if (['search'].includes(key)) this.filter[key] = value
          else if (this.multiKeys.includes(key)) {
            this.filter[key] = value.toString().split(',').map(el => +el)
          // } else this.filter[key] = ['search', 'page', 'page_size'].includes(key) ? value : +value
          } else this.filter[key] = value
        })
      }
    },
    searchData () {
      let copyFilter = {
        ...this.$route.query,
        ...this.filter
      }
      Object.entries(copyFilter).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length) copyFilter[key] = value.join()
      })
      let params = { ...this.$route.params, ...this.params }
      this.setQuery(clearBody(copyFilter), clearBody(params))

      this.$emit('querySearch', clearBody(copyFilter))
    },
    getPositionsList (ranks) {
      let positions = []
      ranks &&
        ranks.forEach((item) => {
          positions = positions.concat(this.positionsByRankID(item))
        })
      return positions
    },
    clearFilter () {
      this.filter = initFilterReportSearchV2()
      this.price_f1 = null
      this.price_f2 = null
      if (Object.keys({ ...this.$route.query }).length === 2) return
      if (this.$refs.customComponentFromDateTime) this.$refs.customComponentFromDateTime.clearBody()
      if (this.$refs.customComponentToDateTime) this.$refs.customComponentToDateTime.clearBody()
      this.setQuery({ ...clearBody(initFilterReportSearchV2()) })
      this.$emit('querySearch', clearBody(initFilterReportSearchV2()))
    },
    async getList (item) {
      switch (this.$route.name) {
        case 'submitted-documents-report':
        case 'sqc-report-statement':
          return this.getUsers(queryString({ type_user: 'crewing_manager', name: item?.length ? item : null }))
        default:
          return []
      }
    },
    saveReport () {
      this.allowSaveExcel = true
      this.$emit('saveReport', clearBody(this.filter))
    },
    async dinamicListSpecializations (item) {
      this.listSpecializationsForSpeciality = this.listSpecializations.filter(el => item.includes(el.speciality))
    },
    async searchSailors (item) {
      let query = item
      // this.interval = setInterval(() => {
      //   window.addEventListener('keyup', (event) => {
      //     // do something
      //   })
      // }, 300)
      if (query && query.length >= 3) await this.getListSailor({ query })
    },
    chooseTypeStatement (item) {
      if (typeof item === 'boolean') {
        this.filter.ces_exam = true
        this.filter.is_continue = null
      } else {
        this.filter.is_continue = item
        this.filter.ces_exam = false
      }
    },
    setInputPriceForm (typeForm, item) {
      switch (this[`filedParam_${typeForm}`].value) {
        case 'more':
          this.filter[`${typeForm}_eq`] = null
          this.filter[`${typeForm}_lte`] = null
          this.filter[`${typeForm}_gte`] = item
          break
        case 'less':
          this.filter[`${typeForm}_eq`] = null
          this.filter[`${typeForm}_gte`] = null
          this.filter[`${typeForm}_lte`] = item
          break
        default:
          this.filter[`${typeForm}_eq`] = item
          this.filter[`${typeForm}_gte`] = null
          this.filter[`${typeForm}_lte`] = null
      }
    }
  }
}
</script>
